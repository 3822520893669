import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import YouTube from 'react-youtube';
export const _frontmatter = {
  "path": "/blog/drupalsouth-2021-security-lightning-talk",
  "date": "11 November 2021",
  "title": "DrupalSouth '21 - Drupal Security Lightning Talk",
  "summary": "This is a video recording from DrupalSouth 2021 where Nick Schuch provides recommendations on where your cloud hosting infrastructure could be improved",
  "author": "Nick Schuch",
  "tag": "Presentation",
  "tagColor": "green",
  "tags": [{
    "name": "meetup"
  }, {
    "name": "presentation"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Overview`}</h2>
    <p>{`This is a video recording from DrupalSouth 2021 where Nick Schuch provides recommendations on where your cloud hosting infrastructure could be improved.`}</p>
    <h2>{`Key Points`}</h2>
    <ul>
      <li parentName="ul">{`Understand your traffic - Understand the types of clients are connecting to your infrastructure.`}</li>
      <li parentName="ul">{`Lock down code - Code should not be able to be modified after being deployed.`}</li>
      <li parentName="ul">{`Detect Intruders - Deploy intrusion detection to monitor for bad actors.`}</li>
      <li parentName="ul">{`Managed Services - Leverage managed services for greater SLAs and security posture.`}</li>
    </ul>
    <h2>{`Recording`}</h2>

    <YouTube videoId="luwOif1mSmo" opts={{
      height: '420',
      width: '100%',
      playerVars: {
        start: '78'
      }
    }} mdxType="YouTube" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      